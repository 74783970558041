
import ButtonComponent from '@/components/ButtonComponent.vue';
import PageLayout from '@/components/Layout/PageLayoutComponent.vue';
import DeleteModalComponent from '@/components/Modals/DeleteModalComponent.vue';
import SponsoringUnitModalComponent from '@/components/Modals/Unit/SponsoringUnitModalComponent.vue';
import LegacyTableComponent from '@/models/Table/Legacy/LegacyTableComponent.vue';
import { SponsoringUnit } from '@/models/Entities/Student/SponsoringUnit';
import { Component, Vue } from 'vue-property-decorator';
import AsyncTableComponent from '../Table/AsyncTableComponent.vue';
import TableSearchService from '../Table/models/TableSearch.service';
import { SponsoringUnitDataProvider } from '../Table/SponsoringUnit/SponsoringUnitDataProvider';
import { SearchSponsoringUnitDto } from '@/models/Dtos/searchSponsoringUnitDto';
import { SponsoringUnitTableData } from '../Table/SponsoringUnit/SponsoringUnitTableData';
import { sponsoringUnitTableConfigBuilder } from '../Table/SponsoringUnit/SponsoringUnitTableConfigBuilder';
import {
  sponsoringUnitAircraftFilter,
  sponsoringUnitBaseFilter,
  sponsoringUnitCityFilter,
  sponsoringUnitPasFilter,
  sponsoringUnitStateFilter,
  sponsoringUnitUnitFilter,
  sponsoringUnitWingFilter,
  sponsoringUnitZipcodeFilter
} from '../Table/SponsoringUnit/SponsoringUnitFilters';
import { ModalMounter } from '../ModalMounter';

@Component<SponsoringUnitClassComponent>({
  components: {
    PageLayout,
    LegacyTableComponent,
    ButtonComponent,
    DeleteModalComponent,
    SponsoringUnitModalComponent,
    AsyncTableComponent
  }
})
export default class SponsoringUnitClassComponent extends Vue {
  $refs!: {
    deleteModalComponent: DeleteModalComponent;
  };

  sponsoringUnitSearchService: TableSearchService<
    SponsoringUnitTableData,
    SponsoringUnit,
    never,
    SearchSponsoringUnitDto
  > | null = null;

  created(): void {
    this.sponsoringUnitSearchService = new TableSearchService<
      SponsoringUnitTableData,
      SponsoringUnit,
      never,
      SearchSponsoringUnitDto
    >(
      this.$store,
      new SponsoringUnitDataProvider(this.$store),
      {},
      { defaultSortProperty: 'unit' },
      sponsoringUnitTableConfigBuilder,
      {
        filters: [
          sponsoringUnitUnitFilter,
          sponsoringUnitZipcodeFilter,
          sponsoringUnitCityFilter,
          sponsoringUnitWingFilter,
          sponsoringUnitBaseFilter,
          sponsoringUnitAircraftFilter,
          sponsoringUnitPasFilter,
          sponsoringUnitStateFilter
        ],
        quickFilters: []
      }
    );
    this.sponsoringUnitSearchService.queryData();
  }

  async createSponsoringUnit(): Promise<void> {
    try {
      const unit = await ModalMounter.instance.refs.sponsoringUnitModal.open(
        -1
      );
      await this.$store.dispatch('sponsoringUnitModule/create', unit);
      this.sponsoringUnitSearchService?.queryData();
      this.showSuccess('Unit created');
    } catch (err) {
      if (err) {
        this.showError('An error occurred while creating the Unit');
      }
    }
  }

  async deleteSponsoringUnit(sponsoringUnit: SponsoringUnit): Promise<void> {
    try {
      await this.$refs.deleteModalComponent.open(sponsoringUnit);
      await this.$store.dispatch(
        'sponsoringUnitModule/delete',
        sponsoringUnit.id
      );
      this.sponsoringUnitSearchService?.queryData();
      this.showSuccess('Unit deleted');
    } catch (err) {
      if (err) {
        this.showError('An error occurred while deleting the Unit');
      }
    }
  }

  showSuccess(message: string): void {
    this.$store.dispatch('snackBarModule/enqueue', {
      message,
      icon: 'checkGreen'
    });
  }
  showError(message = 'An error occurred'): void {
    this.$store.dispatch('snackBarModule/enqueue', {
      message,
      icon: 'caution'
    });
  }
}
