import { ITableData } from '@/components/Table/models/TableData';
import { SponsoringUnit } from '@/models/Entities/Student/SponsoringUnit';

export class SponsoringUnitTableData implements ITableData {
  id!: number;
  entityId: number;
  startDate!: Date;
  graduationDate!: Date;
  deleteClass = true;
  unit!: string;
  wing: string;
  base: string;
  aircraft: string;
  pas: string;
  state: string;
  zipcode: string;
  city: string;
  isArchived: boolean;

  constructor(unit: SponsoringUnit) {
    this.id = unit.id;
    this.entityId = unit.id;
    this.startDate = unit.classStartDate;
    this.graduationDate = unit.classEndDate;
    this.unit = unit.unit;
    this.wing = unit.wing;
    this.base = unit.base;
    this.aircraft = unit.aircraft;
    this.pas = unit.pas;
    this.state = unit.state;
    this.zipcode = unit.zipcode;
    this.city = unit.city;
    this.isArchived = unit.isArchived;
  }
}
