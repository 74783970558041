var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "page-layout",
    {
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function () {
            return [_vm._v("340th Dashboard / Sponsoring Units")]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        { staticClass: "w-full p-4 bg-white" },
        [
          _c(
            "async-table-component",
            _vm._g(
              _vm._b(
                {
                  attrs: {
                    isSortable: true,
                    isColumnEditable: true,
                    isFilterable: true,
                    localStorageKey: "sponsoringUnitTable",
                    emptyTableMessage: "0 active Sponsoring units",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "settings-right",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "flex items-center" },
                            [
                              _c(
                                "button-component",
                                {
                                  attrs: {
                                    "data-cy": "createUnitClassButton",
                                    bg: "val-button-blue",
                                    textOrBorderColor: "white",
                                  },
                                  on: { click: _vm.createSponsoringUnit },
                                },
                                [
                                  _c("span", { staticClass: "text-xs" }, [
                                    _vm._v("Add Sponsoring Unit"),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                "async-table-component",
                _vm.sponsoringUnitSearchService.tableProps,
                false
              ),
              _vm.sponsoringUnitSearchService.tableEventListeners
            )
          ),
        ],
        1
      ),
      _c(
        "delete-modal-component",
        {
          ref: "deleteModalComponent",
          attrs: { size: "small", modalTitle: "Delete Unit" },
        },
        [_vm._v("Are you sure you want to delete this unit?")]
      ),
      _c("sponsoring-unit-modal-component", {
        ref: "sponsoringUnitModalComponent",
        attrs: { size: "medium" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }