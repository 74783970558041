import { TableDataProvider } from '@/components/Table/models/TableDataProvider';
import { SearchResponseDto } from '@/models/Dtos/common/searchResultDto';
import { SearchSponsoringUnitDto } from '@/models/Dtos/searchSponsoringUnitDto';
import { SponsoringUnit } from '@/models/Entities/Student/SponsoringUnit';
import { SponsoringUnitTableData } from './SponsoringUnitTableData';

export class SponsoringUnitDataProvider extends TableDataProvider<
  SponsoringUnitTableData,
  SponsoringUnit,
  SearchSponsoringUnitDto
> {
  protected transformer = (t: SponsoringUnit): SponsoringUnitTableData =>
    new SponsoringUnitTableData(t);

  protected queryHandler = async (
    searchDto: SearchSponsoringUnitDto
  ): Promise<SearchResponseDto<SponsoringUnit>> => {
    const results: SearchResponseDto<SponsoringUnit> =
      await this.store.dispatch(
        'sponsoringUnitModule/fetchWithSelectParameters',
        searchDto
      );
    return results;
  };
}
