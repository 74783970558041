
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component<UnfilledButtonComponent>({})
export default class UnfilledButtonComponent extends Vue {
  @Prop()
  readonly text!: string;

  @Prop()
  readonly action!: () => void;

  @Emit('click')
  clickHandler(): void {
    this.action();
  }
}
