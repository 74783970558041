import { SearchSponsoringUnitDto } from '@/models/Dtos/searchSponsoringUnitDto';
import { stringFilterComponentDataBuilder } from '@/models/Table/Table';

/**
 * Filters go into the filter dropdown and usually have inputs to define the filter value
 * @param string the label showing in the drop down options
 */
export const sponsoringUnitUnitFilter = stringFilterComponentDataBuilder(
  'Unit',
  (t: SearchSponsoringUnitDto, value: string) => {
    t.unit = value;
  }
);
export const sponsoringUnitZipcodeFilter = stringFilterComponentDataBuilder(
  'Zipcode',
  (t: SearchSponsoringUnitDto, value: string) => {
    t.zipcode = value;
  }
);
export const sponsoringUnitCityFilter = stringFilterComponentDataBuilder(
  'City',
  (t: SearchSponsoringUnitDto, value: string) => {
    t.city = value;
  }
);
export const sponsoringUnitWingFilter = stringFilterComponentDataBuilder(
  'Wing',
  (t: SearchSponsoringUnitDto, value: string) => {
    t.wing = value;
  }
);
export const sponsoringUnitBaseFilter = stringFilterComponentDataBuilder(
  'Base',
  (t: SearchSponsoringUnitDto, value: string) => {
    t.base = value;
  }
);
export const sponsoringUnitAircraftFilter = stringFilterComponentDataBuilder(
  'Aircraft',
  (t: SearchSponsoringUnitDto, value: string) => {
    t.aircraft = value;
  }
);
export const sponsoringUnitPasFilter = stringFilterComponentDataBuilder(
  'Pas',
  (t: SearchSponsoringUnitDto, value: string) => {
    t.pas = value;
  }
);
export const sponsoringUnitStateFilter = stringFilterComponentDataBuilder(
  'State',
  (t: SearchSponsoringUnitDto, value: string) => {
    t.state = value;
  }
);
