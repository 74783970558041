
import SponsoringUnitClassComponent from '@/components/Course/SponsoringUnitClassComponent.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component<SponsoringUnitPage>({
  components: {
    SponsoringUnitClassComponent
  }
})
export default class SponsoringUnitPage extends Vue {}
