import { TableConfigBuilder } from '@/components/Table/models/TableConfigBuilder';
import TableSearchService from '@/components/Table/models/TableSearch.service';
import { SearchSponsoringUnitDto } from '@/models/Dtos/searchSponsoringUnitDto';
import { SponsoringUnit } from '@/models/Entities/Student/SponsoringUnit';
import { ITableConfiguration } from '@/models/Table/Table';
import { RootState } from '@/store/types';
import { Store } from 'vuex';
import { SponsoringUnitTableData } from './SponsoringUnitTableData';
import EntityActionsTableElementComponent from '../../Table/Elements/EntityActionsTableElementComponent.vue';
import { ModalMounter } from '@/components/ModalMounter';
import UnfilledButtonComponent from '@/components/Table/Elements/UnfilledButtonComponent.vue';

export const sponsoringUnitTableConfigBuilder: TableConfigBuilder<
  SponsoringUnitTableData,
  SponsoringUnit,
  never,
  SearchSponsoringUnitDto
> = (
  tableSearchService: TableSearchService<
    SponsoringUnitTableData,
    SponsoringUnit,
    never,
    SearchSponsoringUnitDto
  >,
  store: Store<RootState>
): ITableConfiguration<SponsoringUnitTableData, SponsoringUnit, never> => {
  return {
    columnConfigurations: [
      {
        columnTitle: 'Unit',
        render: {
          componentRef: UnfilledButtonComponent,
          componentProps: (rowData: SponsoringUnitTableData) => {
            return {
              text: rowData.unit,
              action: async () => {
                try {
                  const updatedSponsoringUnit =
                    await ModalMounter.instance.refs.sponsoringUnitModal.open(
                      rowData.id
                    );
                  await store.dispatch('sponsoringUnitModule/update', {
                    id: rowData.id,
                    payload: updatedSponsoringUnit
                  });
                  await tableSearchService.queryData();
                  store.dispatch('snackBarModule/enqueue', {
                    message: 'Unit has been updated',
                    icon: 'checkGreen'
                  });
                } catch (err) {
                  if (err) {
                    store.dispatch('snackBarModule/enqueue', {
                      message: 'The unit failed to update.',
                      icon: 'caution'
                    });
                  }
                }
              }
            };
          }
        },
        sortKey: 'unit',
        defaultPinned: true
      },
      {
        columnTitle: 'Wing',
        render: 'wing',
        sortKey: 'wing'
      },
      {
        columnTitle: 'Base',
        render: 'base',
        sortKey: 'base'
      },
      {
        columnTitle: 'Aircraft',
        render: 'aircraft',
        sortKey: 'aircraft'
      },
      {
        columnTitle: 'PAS',
        render: 'pas',
        sortKey: 'pas'
      },
      {
        columnTitle: 'State',
        render: 'state',
        sortKey: 'state'
      },
      {
        columnTitle: 'Zipcode',
        render: 'zipcode',
        sortKey: 'zipcode',
        defaultPinned: true
      },
      {
        columnTitle: 'City',
        render: 'city',
        sortKey: 'city',
        defaultPinned: true
      },
      {
        columnTitle: 'Actions',
        render: {
          componentRef: EntityActionsTableElementComponent,
          componentProps: (
            rowData: SponsoringUnitTableData
          ): Record<string, unknown> => {
            return {
              data: rowData,
              actions: [
                {
                  icon: 'trash',
                  text: '',
                  iconFill: '#C81111',
                  actionHandler: async () => {
                    const confirmed =
                      await ModalMounter.instance.refs.yesNoModal.open({
                        title: `Delete Sponsoring Unit ${rowData.unit}`,
                        message: `Are you sure you want to delete this sponsoring unit?`,
                        yesLabel: 'Delete',
                        noLabel: 'Cancel'
                      });
                    if (!confirmed) {
                      return;
                    }
                    try {
                      tableSearchService.loaded = false;
                      await store.dispatch(
                        'sponsoringUnitModule/delete',
                        rowData.entityId
                      );

                      return tableSearchService.queryData();
                    } catch {
                      tableSearchService.loaded = true;
                      await store.dispatch('snackBarModule/enqueue', {
                        message: `There was an error deleting the unit.`,
                        icon: 'caution'
                      });
                    }
                  }
                }
              ]
            };
          }
        }
      }
    ]
  };
};
